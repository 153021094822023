var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a1e341e416f426942cac2fb0ee2076f96533ff41"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { isDev } from "@zorba-shared/client/Services/clientService";

Sentry.init({
  enabled: !isDev(),
  whitelistUrls: ['getzorba.com','www.getzorba.com', 'partners.getzorba.com', 'buy.getzorba.com'],  
  dsn: "https://bba81b0241704d24b14f06020211898e@o497229.ingest.sentry.io/6555536",

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1.0,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: ['https://api.getzorba.com/'],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
    }),
  ],
  ignoreErrors: [
    /The operation is insecure./,
    /e[n].toUpperCase is not a function/,
    /ResizeObserver loop limit exceeded/,
    /^No error$/,
    /__show__deepen/,
    /_avast_submit/,
    /Access is denied/,
    /anonymous function: captureException/,
    /Blocked a frame with origin/,
    /can't redefine non-configurable property "userAgent"/,
    /change_ua/,
    /console is not defined/,
    /cordova/,
    /DataCloneError/,
    /Error: AccessDeny/,
    /event is not defined/,
    /feedConf/,
    /ibFindAllVideos/,
    /myGloFrameList/,
    /SecurityError/,
    /MyIPhoneApp/,
    /snapchat.com/,
    /vid_mate_check is not defined/,
    /win\.document\.body/,
    /window\._sharedData\.entry_data/,
    /window\.regainData/,
    /ztePageScrollModule/    
  ],  
});
