import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import Head from 'next/head'

import LayoutComponent from '../src/components/layout/Layout';
import { wrapper } from '../src/store';
import '../styles/antd.scss';
import '../styles/global.scss';
import 'mapbox-gl/dist/mapbox-gl.css';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

function MyApp({ Component, ...rest }) {
  // const [showChild, setShowChild] = useState(false)
  const { store, props } = wrapper.useWrappedStore(rest);
  const { pageProps } = props;

  // useEffect(() => {
  //   setShowChild(true)
  // }, [])

  // if (!showChild) {
  //   return null
  // }
  return (
    <>
      <Head>
        <title key="title">Zorba SFR</title>
      </Head>

      <Provider store={store}>
        <LayoutComponent>
          <Component {...pageProps} />
        </LayoutComponent>
      </Provider>
    </>
  );
}

export default MyApp;
